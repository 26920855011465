import { clientEnvs } from '../index';

export type ClientEnvs = typeof clientEnvs;

export function getClientEnvs(): ClientEnvs {
  return {
    ...clientEnvs,
    DEV_PORT: clientEnvs.DEV_PORT || '8002',
  };
}
